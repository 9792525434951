<template>
  <div>
    <!-- Hero -->
    <base-page-heading
      title="Cotización"
      subtitle="| Administrador de Cotizaciones"
      class="heading-block"
    >
        <template #extra>
            <div class="mt-5 mt-sm-0 ml-sm-3">
            <b-button
                variant="alt-primary"
                class=""
                href="javascript:void(0)"
                v-click-ripple
                v-b-popover.hover.bottom="'Crear un cotización'"
                @click="CreateViewCotizacion()"
                style="width: 200px"
            >
                <i class="fa fa-plus-square mr-1"></i> Nueva Cotización
            </b-button>
            </div>
        </template>
    </base-page-heading>

    <div>
      <base-block rounded title="">
        <b-row>
          <b-col sm="6" xl="2">
            <b-dropdown
              id="dropdown-default-light"
              variant="light"
              text="Acciones agrupadas"
            >
              <b-dropdown-item class="font-size-sm"
                >Habilitar Seleccion</b-dropdown-item
              >
              <b-dropdown-item class="font-size-sm"
                >Deshabilitar Seleccion</b-dropdown-item
              >
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item class="font-size-sm"
                >Eliminar Seleccion</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
          <b-col xl="3" class="ml-3">
            <!-- Form Inline - Alternative Style -->
            <b-form inline>
              <b-form-input
                type="search"
                class="form-control-alt mr-sm-2 mb-sm-0"
                id="example-if-email2"
                name="example-if-email2"
                placeholder="Busqueda"
                v-model="filter"
              ></b-form-input>
            </b-form>
            <!-- END Form Inline - Alternative Style -->
          </b-col>

          <b-col>
            <b-dropdown
              variant="light"
              toggle-class="text-decoration-none"
              no-caret
              class="float-right"
              right
            >
              <template #button-content>
                <i class="si si-settings"></i>
              </template>
              <b-dropdown-item class="font-size-sm" href="/clients.csv" download
                >Exportar Clientes a Excel</b-dropdown-item
              >
            </b-dropdown>
            <b-dropdown
              variant="light"
              toggle-class="text-decoration-none"
              no-caret
              class="mr-1 float-right"
              right
            >
              <template #button-content>
                <i class="fas fa-fw fa-filter"></i>
              </template>
              <b-dropdown-item class="font-size-sm" href="#"
                >Por Nombre</b-dropdown-item
              >
              <b-dropdown-item class="font-size-sm" href="#"
                >Por Departamento</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-row>
        <br />
        <div class="table-responsive">
          <b-table :items="cotizaciones" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" responsive striped hover >
              <template #cell(actions)="row">
                <b-button size="sm" @click="EditarViewCotizacion(row.item.id)" class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled ml-4">
                  <i class="fa fa-fw fa-solid fa-eye"></i>
                </b-button>
              </template>
          </b-table>
        </div>
        <b-row>
            <b-col sm="7" md="2" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
        </b-row>
      </base-block>
    </div>
  </div>
</template>

<script>
import PreCotizacion from "../../../api/admin/cotizacion/Pre_cotizacion";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  data() {
    return {
      tabIndex: 0,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      cotizaciones: [],
      filter: null,
      view_cliente:false,
      edit : false,
      fields: [
        {key: 'id',sortable: true, label: '# ID'},
        {key: 'client',formatter: function(value) {
              console.log(value);
              if(value){
                const jsonStr = JSON.stringify(value)
                const jsonObj = JSON.parse(jsonStr)
                console.log(jsonObj.nombre_comercial)
                return jsonObj.nombre_comercial;
              }
              return "-";
          },sortable: true, label: 'CLIENTE'},
        {key: 'created_at',formatter: function(value) {
              if(value){
                const event = new Date(value);
                const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

                console.log(event.toLocaleDateString('es-MX', options));
                return event.toLocaleDateString('es-MX', options);
              }
              return "-";
          },sortable: true, label: "Fecha"},
        {key: 'estado',sortable: true, label: "ESTADO"},
        {key: 'total',sortable: true, label: "TOTAL"},
        {key: 'actions',sortable: true, label: 'ACCIONES'},
      ],
      
    };
  },
  computed: {
    totalRows() {
      return this.cotizaciones.length
    }
  },
  methods: {

    AllQuots() {
      this.showoverlay = true;
      let auth = JSON.parse(localStorage.autentication);
      PreCotizacion.GetAllQuotFacturadas(auth)
        .then((response) => {
          this.cotizaciones = response.data;
          this.showoverlay = false;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },

    CreateViewCotizacion(){
      /*this.$router.push({
        path: "/cotizacion/create"
      });*/
      // aqui para la parte de cotización vamos a utilizar el mismo componente
      // que esta en la vista de Edit.vue
      // solo que en esa (la de Edit) vamos a manejar una bandera que nos ayude a determinar
      // si una cotización está como nueva o editada.
      // para esto, entonces vamos a manadar el id=0 para leer ese dato
      // y a partir de ese dato vamos a manejar la bandera

      this.$router.push({
        path: "/cotizacion/edit/0",
        params:{
          id: 0,
        }
      });

    },

    EditarViewCotizacion(id){
      this.$router.push({
        path: "/costeo/view/"+id,
        params:{
          id: id,
        }
      });
    },

    TrashCotizacion(id){
      Swal.fire({
        title: "¿Esta seguro de eliminar el registro?",
        text: "¿Eliminar?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      })
      .then(resultado => {
        if (resultado.value) {
          this.showoverlay = true;
          let auth = JSON.parse(localStorage.autentication);
          PreCotizacion.TrashCotizacion(id, auth)
          .then((response) => {
            this.AllQuots();
            this.showoverlay = false;
            Swal.fire({
              title: "Genial!",
              text: response.data.message,
              icon: "success",
            });
            
          })
          .catch((error) => {
            Swal.fire({
              title: "Que mal!",
              text: error.message,
              icon: "error",
            });
          });
        } else {
          // Dijeron que no
          console.log("*NO se elimina la venta*");
        }
      });
    },
   
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-light", "text-dark"];
      } else {
        return ["bg-light", "text-dark"];
      }
    },


  },

  mounted() {
    let me = this;
    me.AllQuots();
  },
};
</script>

<style scoped>
.heading-block {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 60px;
  z-index: 1;
}
.scrollable {
  overflow-y: auto;
  max-height: 280px;
}
</style>
